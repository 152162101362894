import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchHandoverTemplates(ctx, params) {
      return useJwt.getHandoverTemplates(params).then(response => response);
    },
    createHandoverTemplate(ctx, data) {
      return useJwt.createHandoverTemplate(data).then(response => response);
    },
    updateHandoverTemplate(ctx, data) {
      return useJwt.updateHandoverTemplate(data).then(response => response);
    },
    previewHandoverTemplate(ctx, id) {
      return useJwt.previewHandoverTemplate(id).then(response => response);
    },
    deleteHandoverTemplates(ctx, data) {
      return useJwt.deleteHandoverTemplates(data).then(response => response);
    },
  },
};
