import { ref, watch, onUnmounted } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import handoverTemplateStoreModule from '../handoverTemplateStoreModule';

export default function useHandoverTemplateList() {
  const STORE_MODULE_NAME = 'handoverTemplate';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, handoverTemplateStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  // add / edit
  const blankItem = {
    name: '',
    description: '',
    url: '',
    default: true,
    file: null,
    isLiquidContract: false,
    typeObject: null,
  };
  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };
  const { t } = useI18nUtils();
  // Table Handlers
  const columns = [
    {
      label: t('Mã'),
      field: 'code',
      width: '100px',
    },
    {
      label: t('Tên'),
      field: 'name',
    },
    {
      label: t('Mặc định'),
      field: 'default',
      width: '100px',
    },
    {
      label: 'Thao tác',
      field: 'action',
      sortable: false,
      width: '100px',
    },
  ];
  const rows = ref([]);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call

  const fetchHandoverTemplates = () => {
    store
      .dispatch('handoverTemplate/fetchHandoverTemplates', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };
  const refetchHandoverTemplates = () => {
    fetchHandoverTemplates();
  };

  const deleteHandoverTemplates = handoverTemplates => {
    store
      .dispatch('handoverTemplate/deleteHandoverTemplates', {
        ids: handoverTemplates.map(_obj => _obj.id),
      })
      .then(() => {
        fetchHandoverTemplates();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const createHandoverTemplate = () => {
    store
      .dispatch('handoverTemplate/createHandoverTemplate', {
        name: item.value.name,
      })
      .then(() => {
        fetchHandoverTemplates();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchHandoverTemplates();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = data => {
    item.value = data;
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });

  return {
    item,
    resetItem,

    columns,
    rows,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    refetchHandoverTemplates,
    fetchHandoverTemplates,
    deleteHandoverTemplates,
    createHandoverTemplate,

    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    onEditItem,
    t,
  };
}
